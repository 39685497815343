import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import BackgroundImage from "../images/fundo-produtos.jpg";
import Order from "../components/order/Order";

const EncomendasPage = () => (
  <Layout>
    <SEO title="Encomendas" keywords={["encomendas"]} image={BackgroundImage} />

    <HeaderImage image={BackgroundImage}>
      <MainLayout size="60vh">
        <Header title="Encomende aqui os seus produtos" subtitle="Encomendas" />
      </MainLayout>
    </HeaderImage>
    <Order />
  </Layout>
);

export default EncomendasPage;
