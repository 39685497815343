import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { H1 } from "../../styles/typography";

const formatCurrency = (value) =>
  value.toLocaleString("pt-PT", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "symbol",
    style: "currency",
  });

const OrderProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderProductsSubContainer = styled.div``;

const ProductsTitle = styled(H1)`
  color: #3a4648;
  text-align: center;
`;

const ProductImage = styled(GatsbyImage)`
  display: none;
  filter: drop-shadow(10px 10px 10px #000);

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #3a4648;
  margin-bottom: 7px;
`;

const SharedInputStyled = css`
  font-size: 15px;
  background-color: #ffffff;
  border: none;
  height: 50px;
  padding: 10px;
  width: 100%;
  color: #3a4648;
  box-sizing: border-box;
  border: 1px solid #3a4648;
  outline: none;
  border-radius: 5px;
  ::placeholder {
    color: #3a4648;
  }
`;

const StyledInput = styled.input`
  ${SharedInputStyled}
  max-width: 100px;
  height: 50px;
  margin-left: 20px;

  @media screen and (min-width: 768px) {
    max-width: 200px;
  }
`;

const ProductRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const ProductImageContainer = styled.div`
  text-align: left;

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
`;

const Description = styled.p`
  text-align: left;
  font-size: 14px;
`;

const Cost = styled(Label)`
  margin: 0 0 0 20px;
  font-size: 18px;
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #3a4648;
  margin-bottom: 30px;
`;

const producstPrice = 2.6

const OrderProducts = () => {
  const [products, setProducts] = useState({
    shitake: 0,
    pleurotus: 0,
    desidratados: 0,
  });
  const [total, setTotal] = useState(0);
  const { shitake, pleurotus, desidratados } = useStaticQuery(graphql`
    {
      shitake: file(relativePath: { eq: "shitake.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            height: 100
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      pleurotus: file(relativePath: { eq: "pleurotus.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            height: 70
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      desidratados: file(relativePath: { eq: "desidratados.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            height: 100
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  const onQuantityChange = (e, product) => {
    const parsedValue = parseInt(e.target.value, 10);

    if (!Number.isNaN(parsedValue) && parsedValue >= 0) {
      setProducts({ ...products, [product]: parsedValue });
    } else {
      setProducts({ ...products, [product]: 0 });
    }
  };

  useEffect(() => {
    let calculatedTotal = 0;
    Object.keys(products).forEach((product) => {
      calculatedTotal += products[product] * producstPrice;
    });

    setTotal(calculatedTotal);
  }, [products]);

  return (
    <>
      <ProductsTitle>Produtos</ProductsTitle>
      <OrderProductsContainer>
        <OrderProductsSubContainer>
          <ProductRow>
            <ProductImage
              image={shitake.childImageSharp.gatsbyImageData}
              alt="Shitake"
            />
            <ProductImageContainer>
              <Label htmlFor="shitake">Shitake</Label>
              <Description>Embalagem com aproximadamente 250g</Description>
            </ProductImageContainer>
            <StyledInput
              type="number"
              id="shitake"
              name="shitake"
              min="0"
              defaultValue="0"
              required
              onChange={(e) => onQuantityChange(e, "shitake")}
            />
            <Cost>{formatCurrency(producstPrice)}</Cost>
          </ProductRow>

          <ProductRow>
            <ProductImage
              image={pleurotus.childImageSharp.gatsbyImageData}
              alt="Pleurotus"
            />
            <ProductImageContainer>
              <Label htmlFor="pleurotus">Pleurotus</Label>
              <Description>Embalagem com aproximadamente 250g</Description>
            </ProductImageContainer>
            <StyledInput
              type="number"
              id="pleurotus"
              name="pleurotus"
              min="0"
              defaultValue="0"
              required
              onChange={(e) => onQuantityChange(e, "pleurotus")}
            />
            <Cost>{formatCurrency(producstPrice)}</Cost>
          </ProductRow>

          <ProductRow>
            <ProductImage
              image={desidratados.childImageSharp.gatsbyImageData}
              alt="Desidratados"
            />
            <ProductImageContainer>
              <Label htmlFor="desidratados">Desidratados</Label>
              <Description>Embalagem com aproximadamente 250g</Description>
            </ProductImageContainer>
            <StyledInput
              type="number"
              id="desidratados"
              name="desidratados"
              min="0"
              defaultValue="0"
              required
              onChange={(e) => onQuantityChange(e, "desidratados")}
            />
            <Cost>{formatCurrency(producstPrice)}</Cost>
          </ProductRow>
          <TotalContainer>Total: {formatCurrency(total)}</TotalContainer>
        </OrderProductsSubContainer>
      </OrderProductsContainer>
    </>
  );
};

export default OrderProducts;
